import 'bootstrap';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';
import './scss/vendor.scss';
import './scss/app.scss';
// import SupplyChainDisruption from './react/supply_chain_disruption';
// import React from 'react';
// import ReactDOM from 'react-dom/client';

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

var csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  },
  headers: { 'x-ajax': 'true' },
});

window.initTooltips = function () {
  $('[data-toggle="tooltip"]').tooltip();
};

$(function () {
  initTooltips();
  $('#add-modal').on('shown.bs.modal', function () {
    initTooltips();
  });
  $('[data-prompt="delete"]').on('click', promptDelete);
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-primary m-2',
  },
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  icon: 'success',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function promptDelete(el) {
  var $this = $(this);
  var formId = $this.data('prompt-form-id');
  var type = $this.data('prompt-type');
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this ' + type + '.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        $(`#${formId}`)
          .append('<input type="hidden" name="delete">')
          .trigger('submit');
      }
    });
}

window.Swal = swalWithBootstrapButtons;
window.Toast = Toast;

window.debounceLeading = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};

window.debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

window.scrollIntoViewIfNeeded = (target) => {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(false);
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
};

// function initReact(id, El) {
//   const domContainer = document.getElementById(id);
//   const root = ReactDOM.createRoot(domContainer);
//   root.render(<El />);
// }

// window.initSupplyChain = function (id) {
//   initReact(id, SupplyChainDisruption);
// };
